window.addEventListener('load', function() {
  //get all possible radio inputs inside a div with class toggleselect
  var allRadioButtons = document.querySelectorAll('div.hs-toggle-select input[type=radio], div.hs-toggle-select-small input[type=radio]');

  Array.prototype.forEach.call(allRadioButtons, function (r) {

    r.addEventListener('click', function (r) {

      var rb = r.target;

      //find matching label for current radio input
      var l = findLableForControl(rb);

      //if the current radio input is actually checked (extra check just to make sure nothing went wrong)
      if (rb.checked) {
        //get all other radio inputs within the same name group
        var otherRadioButtons = document.querySelectorAll('input[type=radio][name=' + r.target.name + ']')

        /*for each other radio input, get the label and remove the active class*/
        otherRadioButtons.forEach(function (orb) {
          orb.labels.forEach(function (otherl) {
            otherl.classList.remove('hs-toggle-select-active');
          })
        })

        //add active class to checked radio input label
        l.classList.add('hs-toggle-select-active')
      }
    })
  })

  /*returns the first matching label 
    * TODO? should all the matching labels be returned? 
    * Case? 
    */
  function findLableForControl(el) {
    var labels = el.labels;
    for (var i = 0; i < labels.length; i++) {
      if (labels[i].htmlFor == el.id)
        return labels[i];
    }
  }
})