(function() {
  function Scrollback() {
      var didScroll;
      var dir;
      var isScrolling;
      var allScrollElements;
  
      setupEventListeners();
  
      setInterval(function() {
        if (didScroll) {
          toggleScrollButton();
          didScroll = false;
        }
      }, 250);
  

      function setupEventListeners() {
        document.getElementsByTagName('body')[0].addEventListener('mousewheel', function(e){ 
          dir = e.wheelDelta;
          didScroll = true;
          window.clearTimeout(isScrolling);
        });

        allScrollElements = Array.from(document.getElementsByClassName('hs-scroll-back'))
     
        allScrollElements.forEach(function(el) {
           el.addEventListener('click', function() {
             smoothscroll();
           })
         })
      }

      function toggleScrollButton() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        var show;
        if (dir <= -10 || currentScroll < document.documentElement.clientHeight) {
          show = false;
        }
        else if (dir >= 10) {
          show = true;
          setTimeOut();
        }
        else if (dir > -10 && dir < 10) {
          setTimeOut();
        }

        allScrollElements.forEach(function(el) {
          fade(el, show);
        })
      }

      function setTimeOut() {
        isScrolling = setTimeout(function() {
          allScrollElements.forEach(function(el) {
            fade(el, false);
          })
        },3000);
      }

      function fade(el, show) {
        if (show) {
          el.style.opacity = 1;
        }
        else if (show === false) {
          el.style.opacity = 0;
        }
      }

      function smoothscroll(){ 
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
        if (currentScroll > 0) { 
          window.requestAnimationFrame(smoothscroll); 
          window.scrollTo (0,currentScroll - (currentScroll/5));
          allScrollElements.forEach(function(el) {
            fade(el, show);
          })
        } 
      }
  }

  // Expose scrollback as a module
  if (typeof module === "object" && module.exports) {
    module.exports = Scrollback;
  }
})();
