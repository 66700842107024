var Circle = require('./circles.js');

function Circlegraph(el, opts) {

  var size = el.getBoundingClientRect();
  
  var colors = determineColors(opts.style);
  
  opts.value = opts.value === undefined ? 0 : opts.value;

  return new Circle({
    el:                  el,
    radius:              opts.radius || size.width/2,
    value:               opts.value,
    maxValue:            100,
    width:               (opts.radius/6) || (size.width/6)/2,
    text:                opts.value + '<span class="hs-circles-symbol">%</span>',
    colors:              colors,
    duration:            400,
    //wrpClass:            'circles-wrp',
    textClass:           'hs-circles-text',
    //valueStrokeClass:    'circles-valueStroke',
    //maxValueStrokeClass: 'circles-maxValueStroke',
    styleWrapper:        true,
    styleText:           true 
  });
}

function determineColors(style) {
  if (style === 'danger') {
    return ['#E3E5E7', '#C80001'];
  }
  else if (style === 'warning') {
    return ['#E3E5E7', '#FF8600'];
  }
  else {
    return ['#E3E5E7', '#5BCF6A'];
  }
}

Circlegraph.prototype = {
  constructor: Circlegraph
}

module.exports = Circlegraph;

