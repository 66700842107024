var mask = require('./number-format.js');


function NumberFormat(el) {

    var im = new Inputmask("+99999 99 99 99").mask(el);
  
}

NumberFormat.prototype = {
  constructor: NumberFormat
};

module.exports = NumberFormat;