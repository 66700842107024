var awesomplete = require('./typeahead.js');

function Typeahead(el, opts) {
  this.el = el;
  this.opts = opts;
  var opts = opts;

  var data = this.opts.list;

  if (opts.value) { //if there a property called value we know there is mapping to be done
    data = mapdata(data, opts.value, opts.label ? opts.label : null);
  }

  this.typeahead = new awesomplete(el, {
    list: data,
    minChars: this.opts.minChars ? this.opts.minChars : 2,
    autoFirst: true,
    maxItems: this.opts.maxItems ? this.opts.maxItems : 20,
    sortOrderReversed: this.opts.reverse ? this.opts.reverse : false,
    enableStrictFilterModus: this.opts.enableStrictFilterModus ? this.opts.enableStrictFilterModus : false,
    otherResult: this.opts.other ? this.opts.other : false
  });
  
  /*catches the select event from the library and binds all the properties of the selected item to the typeahead object*/
  var typeahead = this.typeahead;
  el.addEventListener('awesomplete-select', function(evt) { //bind function on selection being made

    if (opts.value && evt.text.value !== "Ander") { //reverse mapping for property names except for "other" option
      typeahead.selectedItem = revertMap(evt.text, opts.value, opts.label ? opts.label : null); //used for ng1 + static js

      if (opts.onSelected) { //set callback value for ng2 directive
        opts.onSelected(revertMap(evt.text, opts.value, opts.label ? opts.label : null)); 
        //opts.onSelected(evt.text);
      }
    }
    else { //no mapping needed (value and label are not overwritten)
      typeahead.selectedItem = evt.text;
      
      if (opts.onSelected) {
        opts.onSelected(evt.text);
      }
    }
    
    //blur
    el.blur();
  })
}

Typeahead.prototype = {
  constructor: Typeahead,

  updateList: function (newData) {
    this.typeahead._list = mapdata(newData, this.opts.value, this.opts.label ? this.opts.label : null, this.opts.other ? this.opts.other : false);
    this.typeahead.evaluate();
  },
  
  getSelected: function() { //returns selected object to user
    return this.typeahead.selectedItem;
  }
};

/*mapping*/
function mapdata(list, valueName, labelName, other) {
  var mappedData = [];
  list.forEach(function (item) {
    var newObject = Object.keys(item).reduce(function (n, current) {
      if (current === valueName) {
        n.value = item[current];
      } else if (current === labelName) {
        n.label = item[current]
      } else {
        n[current] = item[current];
      }
      return n;
    }, {});
    mappedData.push(newObject);
  })
  //adds "other option" to list even when there are no results
  if (mappedData.length <= 0) {
    mappedData.push(other)
  }
  return mappedData; //returns an array that has whatever names the original data has, are mapped to value and label
}

function revertMap(item, valueName, labelName) {
  var revertedObject = Object.keys(item).reduce(function (n, current) {
    if (current === 'value') {
      n[valueName] = item[current];
    } else if (current === 'label') {
      n[labelName] = item[current]
    } else {
      n[current] = item[current];
    }
    return n;
  }, {});
  return revertedObject;
}

module.exports = Typeahead;