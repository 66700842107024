var pika = require('./datepicker.js');

function Datepicker(el, opts) {
  this.el = el;
  this.opts = opts; 
  
  /*prevent the enter key in the inputfield to submit the form*/
  this.el.addEventListener("keydown", function(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  })
  
  //create default date object
  var d;
  if (opts.defaultDate && !(opts.defaultDate instanceof Date)) {
    d = new Date(opts.defaultDate[2], opts.defaultDate[1]-1, opts.defaultDate[0]);
  }
  else {
    d = opts.defaultDate;
  }
  
  this.dp = new pika({
    field: el,
    firstDay: 1,
    yearRange: opts.yearRange ? opts.yearRange : [2000, 2020],
    defaultDate: opts.defaultDate ? d : '',
    setDefaultDate: true,
    showWeekends: true, //special styling for the weekenddays
    minDate: opts.minDate ? opts.minDate : null,
    maxDate: opts.maxDate ? opts.maxDate : null,
    onSelect: function() {
      opts.onSelect ? opts.onSelect() : null;
    }
  });
  
  
}

Datepicker.prototype = {
  constructor: Datepicker,
  getDate: function() {
    return this.dp.getDate();
  },
  setDate: function(dateobject) {
    this.dp.setDate(dateobject);
  }
};

module.exports = Datepicker;